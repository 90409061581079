@import '../abstracts/variables';

@font-face {
  font-family: 'NotoSans-Reqular';
  src: url('../../fonts/NotoSans-Regular.ttf');
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: 'NotoSans-Medium';
  src: url('../../fonts/NotoSans-Medium.ttf');
  font-weight: 500;
  font-style: normal
}
@font-face {
  font-family: 'NotoSans-Bold';
  src: url('../../fonts/NotoSans-Bold.ttf');
  font-weight: 700;
  font-style: normal
}

body {
  margin: 0;
  background-color: $color-layout;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}