.tasks {
  &__item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $fontFamily-NotoSans-Regular;
    line-height: 150%;

    & .ant-radio-wrapper {
        margin-right: 0;
        font-size: $fontSize-xs;
        color: $color-gray;

        & span:nth-child(2):not(.ant-radio-inner) {
          margin: 0 0px;
        }

        &:not(.ant-radio-wrapper:last-child) {
            margin-right: 14px;
        }
    }

    & .ant-radio-wrapper-checked {
        color: $color-black;
    }
  }
}