.comment-modal {
  .ql-toolbar {
    display: none;
  }

  .ql-container {
    height: 160px;
    padding: 12px 16px;
    border: 1px solid $color-gray-light !important;
    border-radius: 2px;
  }

  .ql-editor {
    padding: 0;

    & * {
      font-family: $fontFamily-NotoSans-Regular;
      font-size: 15px;
      color: $color-black;
    }

    & a {
      color: $color-primary;
      text-decoration: underline;
    }

    &::before {
      font-family: $fontFamily-NotoSans-Regular;
      font-size: 15px;
      font-style: normal !important;
      color: $color-gray !important;
    }
  }
}