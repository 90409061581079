// Color variables
$color-layout: #f5f6fA;
$color-primary: #218ade;
$color-primary-dark: #1c77bf;
$color-white: #ffffff;
$color-gray: #6c7682;
$color-gray-light: #e1e2e5;
$color-black: #1a1a1a;
$color-red: #f24d4d;
$color-green: #27ae60;
$color-blue-light: #f7f8fc;
$color-orange: #f97316;
$color-blue: #226DDD;
$color-yellow: #EDAB00;

// Font family variables
$fontFamily-NotoSans-Regular: 'NotoSans-Regular', sans-serif;
$fontFamily-NotoSans-Medium: 'NotoSans-Medium', sans-serif;
$fontFamily-NotoSans-Bold: 'NotoSans-Bold', sans-serif;

// Font size variables
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-xxlg: 32px;