.dashboard-body {
  & .ant-table-tbody .ant-table-cell {
    height: 52px;
  }

  &__date-picker {
    max-width: 194px !important;
  }

  &__event-type-filter {
    max-width: 287px !important;
  }
}