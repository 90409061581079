.hold-modal {
  &__date-picker {
    & .ant-picker-footer {
        display: none;
    }
  }

  & .ant-select {
    &-selector {
      height: 47px !important;
      display: flex;
      align-items: center;
    }

    &-selection-placeholder {
      margin-bottom: 0 !important;
    }

    &-selection-item {
      margin-bottom: 0 !important;
    }
  }

  &__select-dropdown {
    & .rc-virtual-list-scrollbar {
      display: block !important;
      right: 5px !important;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-container {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}