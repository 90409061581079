.phone {
  padding: 14px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $fontFamily-NotoSans-Regular;
  font-size: $fontSize-xxs;
  line-height: 140%;
  column-gap: 10px;

  &__number {
    margin-bottom: 0;
  }

  &__btn {
    height: auto;
    border: none;
    box-shadow: none;
    color: $color-primary;

    &:hover,
    &:focus {
      background: transparent;

      & span {
        color: $color-primary;
      }
    }

    &::after {
      display: none;
    }
  }
}