.statistics {
  &__container {
    display: flex;
    column-gap: 24px;
  }

  &__item {
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-white;
    box-shadow: 0px 2px 4px rgba(20, 69, 141, 0.1);
    border-radius: 2px;

    &_primary {
      background: $color-primary;
      color: $color-white;
    }

    &_description {
      margin: 0;
      font-family: $fontFamily-NotoSans-Regular;
      font-size: $fontSize-xs;
      line-height: 140%;
    }

    &_count {
      font-size: $fontSize-xxlg;
      line-height: 140%;
    }

    & .red {
      color: $color-red;
    }
  
    & .gray {
      color: $color-gray;
    }
  }
}