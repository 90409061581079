.call-modal {
  top: 0;
  max-width: none;
  width: 100% !important;
  margin: 0;
  padding-bottom: 0;
  font-family: $fontFamily-NotoSans-Regular;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $color-white;

  & .ant-modal-content {
    height: 100vh;
    background: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-modal-body {
    padding-top: 16%;
    padding-bottom: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-size: $fontSize-xs;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__user-icon {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 80px;
      height: 80px;
      fill: $color-black;
    }
  }

  &__user-name {
    margin-top: 29px;
    font-size: $fontSize-xlg;
  }

  &__hung-up {
    width: 80px;
    height: 80px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-red;
    border-radius: 50%;
    border-width: 3px;

    &:hover,
    &:focus {
      background: $color-red;
      border-color: $color-white;
    }

    & svg {
      width: 40px;
      height: 40px;
      fill: $color-black;
    }

    &_label {
      margin-top: 14px;
    }
  }
}