.event-details {
  margin-bottom: 30px;
  font-family: $fontFamily-NotoSans-Regular;

  &__care-plan {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: underline;
    white-space: nowrap;
  }
}