@import '../abstracts/variables';

.ant-layout {
  background: none;
}

.ant-input {
  padding: 12px 16px;
  font-family: $fontFamily-NotoSans-Regular;
  font-size: 15px;
  line-height: 140%;
  border-color: $color-gray-light;

  &::placeholder {
    color: $color-gray;
  }

  &-affix-wrapper-status-success {
    border-color: $color-green;

    & svg {
      width: 20px;
      height: 20px;
      color: $color-green;
    }
  }

  &-affix-wrapper-status-error {
    border-color: $color-red;

    & svg {
      width: 20px;
      height: 20px;
      color: $color-red;
    }
  }
}

.ant-menu {
  width: 100%;
  background: transparent;
  border-bottom: none;

  &-horizontal:not(.ant-menu-dark) > &-item,
  &-horizontal:not(.ant-menu-dark) > &-submenu {
    padding: 8px 18px;
    margin: 0;
    border-radius: 2px;
  }

  &-horizontal > &-item,
  &-horizontal > &-submenu {
    top: 0;
  }

  &-title-content {
    color: $color-white;
  }

  &-item-selected::after,
  &-item-active::after,
  &-submenu-selected::after,
  &-submenu-active::after {
    display: none;
  }

  &-submenu-popup {
    width: auto;
  }
}

.ant-space {
  color: $color-white;

  &-item {
    width: max-content;
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  padding-right: 12px;

  & input.ant-input {
    padding: 12px 16px;
  }
}

.ant-picker {
  width: 100%;
  padding: 10px 10px 11px;

  &-range-separator {
    display: none;
  }

  &-suffix {
    margin-bottom: 0 !important;
  }

  &-clear .anticon-close-circle{
    margin-bottom: 0 !important;
  }
}

.ant-picker-input input {
  font-family: $fontFamily-NotoSans-Regular;
  font-size: $fontSize-xs;
  line-height: 140%;

  &::placeholder {
    color: $color-gray;
  }
}

.ant-radio {
  &-inner {
    width: 24px;
    height: 24px;
    border-color: $color-green;

    &::after {
      width: 18px;
      height: 18px;
      margin-top: -9px;
      margin-left: -9px;
      background-color: $color-green;
    }
  }

  &:hover,
  &-checked {
    .ant-radio-inner {
      border-color: $color-green;
    }
  }

  &-input:focus + &-inner {
    border-color: $color-green;
    box-shadow: none;
  }

  &-wrapper {
    align-items: start;

    & span:nth-child(2):not(.ant-radio-inner) {
      display: inline-block;
      padding: 4px 4px 0;
      margin: 0 12px;
    }
  }
}

.ant-checkbox {
  &-inner {
    width: 24px;
    height: 24px;

    &::after {
      left: 35%;
    }
  }

  &-checked &-inner {
    background-color: $color-white;
    border-color: $color-green;

    &::after {
      border: 2px solid $color-green;
      border-top: 0;
      border-left: 0;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $color-green;
    }
  }

  &-input:focus + &-inner,
  &-checked::after {
    border-color: $color-green;
  }

  &-wrapper {
    align-items: start;

    & span:nth-child(2):not(.ant-checkbox-inner) {
      display: inline-block;
      padding: 4px 4px 0;
      margin: 0 12px;
    }
  }
}

.ant-btn {
  height: 42px;
  padding: 4px 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: $fontFamily-NotoSans-Medium;
  line-height: 140%;
  border-color: $color-gray-light;

  & svg {
    width: 20px;
    height: 20px;
  }

  & span {
    margin-left: 0;
  }
}

.ant-space-item a {
  text-decoration: underline;
}

.ant-table {
  & * {
    font-family: $fontFamily-NotoSans-Regular;
    font-size: $fontSize-xxs;
    line-height: 150%;
  }

  & table {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }

  & .ant-table-thead th {
    border-bottom: 1px solid #d9d9d9;
  }

  &-wrapper {
    margin-bottom: 24px;
  }

  & th {
    background: transparent !important;

    &::before {
      display: none;
    }
  }

  &-tbody {
    & tr:nth-child(odd) {
      background: $color-blue-light;
    }

    & tr:nth-child(even) {
      background: transparent;
    }

    & > tr > td {
      padding: 5px 8px !important;
      border: none;
    }

    & > tr:nth-child(odd):hover > td {
      background: $color-blue-light;
    }

    & > tr:nth-child(even):hover > td {
      background: transparent;
    }
  }

  &-thead > tr > th {
    padding: 5px 8px !important;
    border: none;
    color: $color-gray;
  }

  & a {
    text-decoration: underline;
  }
}

.ant-modal {
  &-content {
    padding: 32px 32px 40px;
  }

  &-header {
    padding: 0;
    border-bottom: none;
  }

  &-title {
    font-family: $fontFamily-NotoSans-Medium;
    font-size: $fontSize-xlg;
    line-height: 140%;
  }

  &-body {
    padding: 0;
  }

  &-footer {
    padding: 0;
    border-top: none;
    display: flex;
    align-items: center;
  }
}
