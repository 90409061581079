.event-body {
  font-family: $fontFamily-NotoSans-Medium;
  font-size: $fontSize-xs;
  line-height: 140%;
  color: $color-black;

  &__header {
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &_item {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;

      &-title {
        color: $color-gray;
      }
    }
  }
}