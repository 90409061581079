.actions-card {
  padding: 32px 32px 40px;
  background: $color-white;
  box-shadow: 0px 2px 4px rgba(20, 69, 141, 0.1);
  border-radius: 2px;
  margin-bottom: 24px;

  &__item {
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray-light;

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }

  &__actions {
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__comment-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../images/svg/comment-icon.svg);
  }

  &__hold-icon {
    width: 24px;
    height: 24px;
    background-image: url(../../images/svg/snooze-icon.svg);
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__hold-info {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    color: $color-orange;

    & .bold {
      font-family: $fontFamily-NotoSans-Bold;
    }

    &-icon {
      width: 32px;
      height: 32px;

      & path {
        fill: $color-orange;
      }
    }
  }
}