.login-page {
  &__wrapper {
    max-width: 648px;
    margin: 0 auto;
    margin-top: 80px;
    padding: 40px 56px 64px;
    background: $color-white;
    border-radius: 2px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}