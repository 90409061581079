.filter {
  &__row {
    padding-bottom: 24px;
    display: flex;
    column-gap: 24px;
  }

  & .ant-picker {
    max-width: 392px;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    line-height: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  & .ant-menu-horizontal {
    width: auto;
    line-height: 0;
  }

  & .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 10px 24px;
    box-sizing: border-box;
  }

  & .ant-menu-item {
    border: 1px solid $color-gray-light;

    & .ant-menu-title-content {
      font-family: $fontFamily-NotoSans-Regular;
      line-height: 140%;
      color: $color-black;
    }
  }

  & .ant-menu-item-selected {
    background: $color-primary;
    color: $color-white;
    border: none;

    & .ant-menu-title-content {
      color: $color-white;
    }

    &:hover {
      background: $color-primary;
    }
  }

  & .ant-radio-group {
    margin: 5px 0 0 16px;
  }

  & .ant-checkbox-wrapper {
    margin: 7px 16px 0;
  }

  &__btn_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 24px;

    &_title {
      color: $color-gray;
    }

    &_selector {
      flex: 1;
      height: 100%;

      & .ant-select-selector {
        height: auto !important;
        padding: 7px 16px !important;
      }
    }
  }
}