.patient-details {
  padding: 24px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  background: $color-layout;
  border-radius: 2px;
  font-family: $fontFamily-NotoSans-Regular;
  font-size: $fontSize-xxs;

  &__common {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__info {
    max-width: 700px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 8px;
  }

  &__item {
    margin-right: 4px;
    color: $color-gray;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: underline;
    white-space: nowrap;
  }
}