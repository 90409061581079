.history-card {
  padding: 32px 32px 40px;
  background: $color-white;
  box-shadow: 0px 2px 4px rgba(20, 69, 141, 0.1);
  border-radius: 2px;

  &__title {
    margin-bottom: 15px;
  }

  &__wrapper {
    position: relative;
    height: 450px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    font-family: $fontFamily-NotoSans-Regular;
    border-bottom: 1px solid $color-layout;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none
    }

    &_header {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: $fontSize-xxxs;
      color: $color-gray;
    }

    &_description {
      font-size: $fontSize-xs;
    }

    &_comment {
      font-size: $fontSize-xxs;

      &-title {
        margin-right: 4px;
        color: $color-gray;
      }

      &-red {
        color: $color-red;
      }
    }

    &_btn-edit {
      height: auto;
      margin-top: 6px;
      column-gap: 0;
      font-family: $fontFamily-NotoSans-Regular;

      & svg {
        width: 16px;
        height: 16px;
        margin-right: 3px;

        & path {
          fill: $color-primary;
        }
      }
    }
  }
}